import { scan } from 'react-scan';

import { createContext } from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Provider } from 'mobx-react';
import { RouterStore, syncHistoryWithStore } from '@ibm/mobx-react-router';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

// Stores
import AuthStore from './stores/AuthStore';
import UiState from './stores/UiState';
import ClientStore from './stores/ClientStore';
import UserStore from './stores/UserStore';
import BroadcastStore from './stores/BroadcastStore';
import smoothscroll from 'smoothscroll-polyfill';
import './_scss/_index.scss';

import AppContainer from './AppContainer';

import Hotjar from '@hotjar/browser';
import { backController } from './utils/back-controller';

scan({
  enabled:
    process.env.REACT_APP_ENV === 'local' &&
    process.env.REACT_APP_SCAN_ENABLED === 'true',
  log: true, // logs render info to console (default: false)
  showToolbar: true,
  runInProduction:
    process.env.REACT_APP_ENV === 'local' &&
    process.env.REACT_APP_SCAN_ENABLED === 'true'
});

if (process.env.REACT_APP_HOTJAR_ENABLED === 'true') {
  const siteId = process.env.REACT_APP_HOTJAR_SITE_ID;
  const hotjarVersion = process.env.REACT_APP_HOTJAR_VERSION;

  Hotjar.init(siteId, hotjarVersion, {
    debug: process.env.REACT_APP_ENV === 'hylyght_demo'
  });
}

// setup all stores
const routing = new RouterStore();
const uiState = new UiState();
const clientStore = new ClientStore(routing, uiState);
const userStore = new UserStore(routing, uiState);
// const entityStore = new EntityStore(routing, uiState, userStore);
const authStore = new AuthStore(routing, uiState);
const broadcastStore = new BroadcastStore();

const stores = {
  routing,
  authStore,
  // entityStore,
  uiState,
  clientStore,
  userStore,
  broadcastStore
};

export const StoreContext = createContext(stores);

const render = client => {
  const browserHistory = createBrowserHistory();
  const history = syncHistoryWithStore(browserHistory, routing);

  if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      tunnel: process.env.REACT_APP_SENTRY_TUNNEL,
      integrations: [
        Sentry.reactRouterV5BrowserTracingIntegration({ history })
      ],
      environment: process.env.REACT_APP_ENV,
      // replaysSessionSampleRate: 0.1,
      // replaysOnErrorSampleRate: 1.0,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0
    });
  }

  backController.setPath(browserHistory.location.pathname);
  history.listen(location => {
    if (location?.pathname) {
      backController.setPath(location.pathname);
    }
  });

  const container = document.getElementById('root');
  const root = createRoot(container); // createRoot(container!) if you use TypeScript
  root.render(
    <Provider {...stores}>
      <Router history={history}>
        <AppContainer client={client} stores={stores} />
      </Router>
    </Provider>
  );
};

/**
 * responsible for persistance of stores every store we want te keep alive between refreshes
 * we have to wait for the persiststore finish before we can render our views
 */
const persistStores = async () => {
  /*const hydrate = create({
    storage: localStorage,
    jsonify: false
  });
  await hydrate('authStore', authStore).then(r => {
    r.isLoggedIn = false;
  });
  await hydrate('uiState', stores.uiState);*/
  const client = await authStore.connect();
  uiState.setLocale();
  render(client);
  await authStore.setLoginState();
};

//registerServiceWorker();
persistStores();

smoothscroll.polyfill();
