import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef
} from 'react';
import * as Sentry from '@sentry/react';
import { GrowthTrackerEstimationMethods } from 'constants.js';
import { useHistory } from 'react-router-dom';
import { differenceInWeeks } from 'date-fns';
import { QUERY_GET_GROWTH_DATA_OF_GROUP } from 'services/aws/growthtracker-query';
import { useLazyQuery } from '@apollo/client';
import { createColumnHelper } from '@tanstack/react-table';

export const GrowthPanelContext = createContext({});

const columnHelper = createColumnHelper();

const formatCell = data => {
  return data ? `${data.value} ${data.unit}` : '-';
};

const sortByValue = (rowA, rowB, _columnId) => {
  const valueA = Number(rowA.original[_columnId].value);
  const valueB = Number(rowB.original[_columnId].value);

  return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
};

const sortByFirstValue = (rowA, rowB, _columnId) => {
  const valueA = Number(rowA.original[_columnId].value.split(' - ')[0]);
  const valueB = Number(rowB.original[_columnId].value.split(' - ')[0]);

  return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
};

const sortByAlertLevel = (rowA, rowB, _columnId) => {
  const valueA = Number(rowA.original[_columnId].alertLevel);
  const valueB = Number(rowB.original[_columnId].alertLevel);

  return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
};

const sortByArrayValue = (rowA, rowB, _columnId) => {
  const valueA = Number(rowA.original[_columnId].value[1]);
  const valueB = Number(rowB.original[_columnId].value[1]);

  return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
};

const GrowthTrackerColumns = [
  columnHelper.accessor('person', {
    header: () => '',
    accessorFn: row => `${row.person.firstname} ${row.person.lastname}`,
    size: 200,
    minSize: 150,
    sortingFn: 'alphanumeric',
    enableHiding: false
  }),
  columnHelper.accessor('preciseAge', {
    header: () => 'Chrono Age',
    cell: props => formatCell(props),
    size: 48,
    widthExpanded: 64,
    sortingFn: sortByValue
  }),
  columnHelper.accessor('bodyHeight', {
    header: () => 'Body Height',
    cell: props => formatCell(props),
    size: 64,
    widthExpanded: 64,
    sortingFn: sortByValue
  }),
  columnHelper.accessor('bodyWeight', {
    id: 'bodyWeight',
    header: () => 'Body Weight',
    cell: props => formatCell(props),
    size: 64,
    widthExpanded: 64,
    sortingFn: sortByValue
  }),
  columnHelper.accessor('adultHeightPrediction', {
    id: 'adultHeightPrediction',
    header: () => (
      <>
        A. H.
        <br />
        Predict.
      </>
    ),
    cell: props => formatCell(props),
    size: 64,
    widthExpanded: 96,
    sortingFn: sortByValue
  }),
  columnHelper.accessor('pah90Ci', {
    id: 'pah90Ci',
    header: () => 'PAH 90% CI',
    cell: props => formatCell(props),
    size: 96,
    widthExpanded: 120,
    sortingFn: sortByFirstValue
  }),
  columnHelper.accessor('predictedAdultHeight', {
    id: 'predictedAdultHeight',
    header: () => (
      <>
        % Pred. Ad.
        <br />
        Height (K-R)
      </>
    ),
    cell: props => formatCell(props),
    size: 120,
    widthExpanded: 120,
    isBasic: true,
    sortingFn: sortByValue
  }),
  columnHelper.accessor('growthPhase', {
    id: 'growthPhase',
    header: () => (
      <>
        Maturation
        <br />
        Status
      </>
    ),
    cell: props => formatCell(props),
    size: 96,
    widthExpanded: 120,
    sortingFn: sortByAlertLevel
  }),
  columnHelper.accessor('developmentZScore', {
    id: 'developmentZScore',
    header: () => (
      <>
        Maturation
        <br />
        Timing
      </>
    ),
    cell: props => formatCell(props),
    size: 96,
    widthExpanded: 120,
    sortingFn: sortByArrayValue
  }),
  columnHelper.accessor('heightVelocity', {
    id: 'heightVelocity',
    header: () => (
      <>
        Growth
        <br />
        Velocity
      </>
    ),
    cell: props => formatCell(props),
    size: 128,
    widthExpanded: 120,
    isBasic: true,
    sortingFn: sortByValue
  }),
  columnHelper.accessor('weightVelocity', {
    id: 'weightVelocity',
    header: () => (
      <>
        Weight
        <br />
        Velocity
      </>
    ),
    cell: props => formatCell(props),
    size: 128,
    widthExpanded: 120,
    isBasic: true,
    sortingFn: sortByValue
  }),
  columnHelper.accessor('bioAge', {
    id: 'bioAge',
    header: () => <>Bio Age</>,
    cell: props => formatCell(props),
    size: 48,
    widthExpanded: 64,
    sortingFn: sortByValue
  }),
  columnHelper.accessor('chronoVsBio', {
    id: 'chronoVsBio',
    header: () => (
      <>
        Chrono
        <br />
        vs Bio Age
      </>
    ),
    cell: props => formatCell(props),
    size: 96,
    sortingFn: sortByValue
  })
];

const getAlertOldData = data => {
  return (
    data.heightEvolutionData.length > 0 &&
    (differenceInWeeks(
      new Date(),
      new Date(
        data.heightEvolutionData[
          data.heightEvolutionData.length - 1
        ].testDateTime
      )
    ) > 10 ||
      differenceInWeeks(
        new Date(),
        new Date(
          data.weightEvolutionData[
            data.weightEvolutionData.length - 1
          ].testDateTime
        )
      ) > 10)
  );
};

const getPAHLevel = growthPhase => {
  switch (true) {
    case growthPhase.toLowerCase().indexOf('pre') >= 0:
      return 3;

    case growthPhase.toLowerCase().indexOf('start') >= 0:
      return 1;

    case growthPhase.toLowerCase().indexOf('circa') >= 0:
      return 0;

    case growthPhase.toLowerCase().indexOf('post') >= 0:
      return 4;

    default:
      return 2;
  }
};

const getValue = testItemValues => {
  return testItemValues && Object.keys(testItemValues).length > 0
    ? testItemValues[0]
    : false;
};

const getRowData = data => {
  return {
    preciseAge: {
      value: data.preciseAge ? data.preciseAge : false,
      unit: 'yearShort'
    },
    bodyHeight: {
      value: getValue(
        data.testItemValues['19688d86-a4e8-4cfb-966d-69bdf035f6ab']
      ),
      unit: 'cm'
    },
    bodyWeight: {
      value: getValue(
        data.testItemValues['a2a243bc-8d3e-4eb8-9837-9a3bb814488d']
      ),
      unit: 'kg'
    },
    adultHeightPrediction: {
      value: getValue(
        data.testItemValues['5737716d-7eb3-4724-85d2-7baa4652ae93']
      ),
      unit: 'cm'
    },
    pah90Ci: {
      value: getValue(
        data.testItemValues['f7529cf9-ba05-4570-bedd-6213ee3b6ec8']
      ),
      unit: 'cm'
    },
    predictedAdultHeight: {
      value: getValue(
        data.testItemValues['face72bd-d260-44a3-b55c-3bae9e3d18dc']
      ),
      unit: '%',
      alertLevel: !!getValue(
        data.testItemValues['197bbf37-7db5-40c4-9675-0dec23339b8e']
      )
        ? getPAHLevel(
            data.testItemValues['197bbf37-7db5-40c4-9675-0dec23339b8e'][0]
          )
        : false,
      iconWarning: !!getValue(
        data.testItemValues['197bbf37-7db5-40c4-9675-0dec23339b8e']
      )
        ? getPAHLevel(
            data.testItemValues['197bbf37-7db5-40c4-9675-0dec23339b8e'][0]
          ) <= 1
        : false
    },
    growthPhase: {
      value: getValue(
        data.testItemValues['197bbf37-7db5-40c4-9675-0dec23339b8e']
      ),
      unit: '',
      alertLevel: !!getValue(
        data.testItemValues['197bbf37-7db5-40c4-9675-0dec23339b8e']
      )
        ? getPAHLevel(
            data.testItemValues['197bbf37-7db5-40c4-9675-0dec23339b8e'][0]
          )
        : false,
      iconWarning: !!getValue(
        data.testItemValues['197bbf37-7db5-40c4-9675-0dec23339b8e']
      )
        ? getPAHLevel(
            data.testItemValues['197bbf37-7db5-40c4-9675-0dec23339b8e'][0]
          ) <= 1
        : false
    },
    developmentZScore: {
      value: [
        getValue(data.testItemValues['0c58cad6-8a6f-402f-b30e-b00e06871f14']),
        !!getValue(data.testItemValues['105b2d29-1469-4d27-bdc0-9d9b6c44b915'])
          ? getValue(
              data.testItemValues['105b2d29-1469-4d27-bdc0-9d9b6c44b915']
            ).toFixed(2)
          : false
      ],

      unit: ''
    },
    heightVelocity: {
      value:
        data.heightEvolutionData &&
        data.heightEvolutionData.length > 0 &&
        data.heightEvolutionData[data.heightEvolutionData.length - 1]
          .growthYear,
      unit: 'cmYearShort',
      alert:
        data.heightEvolutionData &&
        data.heightEvolutionData.length > 0 &&
        differenceInWeeks(
          new Date(),
          new Date(
            data.heightEvolutionData[
              data.heightEvolutionData.length - 1
            ].testDateTime
          )
        ) > 10,
      alertLevel:
        data.heightEvolutionData &&
        data.heightEvolutionData.length > 0 &&
        data.heightEvolutionData[data.heightEvolutionData.length - 1]
          .alertLevel,
      iconWarning:
        data.heightEvolutionData &&
        data.heightEvolutionData.length > 0 &&
        data.heightEvolutionData[data.heightEvolutionData.length - 1]
          .alertLevel < 2
    },
    weightVelocity: {
      value:
        data.weightEvolutionData &&
        data.weightEvolutionData.length > 0 &&
        data.weightEvolutionData[data.weightEvolutionData.length - 1]
          .growthYear,
      unit: 'kgYearShort',
      alert:
        data.weightEvolutionData &&
        data.weightEvolutionData.length > 0 &&
        differenceInWeeks(
          new Date(),
          new Date(
            data.weightEvolutionData[
              data.weightEvolutionData.length - 1
            ].testDateTime
          )
        ) > 10,
      alertLevel:
        data.weightEvolutionData &&
        data.weightEvolutionData.length > 0 &&
        data.weightEvolutionData[data.weightEvolutionData.length - 1]
          .alertLevel,
      iconWarning:
        data.weightEvolutionData &&
        data.weightEvolutionData.length > 0 &&
        data.weightEvolutionData[data.weightEvolutionData.length - 1]
          .alertLevel < 2
    },
    bioAge: {
      value: getValue(
        data.testItemValues['5737716d-7eb3-4724-85d2-7baa4652XXXX']
      ),
      unit: 'yearShort'
    },
    chronoVsBio: {
      value: getValue(
        data.testItemValues['491e8645-9273-4ef4-be2d-5d09363ac088']
      ),
      unit: 'yearLong'
    }
  };
};

const GrowthPanelContextProvider = ({ groupId, children }) => {
  const location = useHistory();

  const usersArray = useRef([]);

  const [rows, setRows] = useState([]);
  const [cols, setCols] = useState([]);
  const [sorting, setSorting] = useState([
    {
      id: 'person',
      desc: false
    }
  ]);
  const [columnOrder, setColumnOrder] = useState([
    'person',
    'heightVelocity',
    'predictedAdultHeight',
    'weightVelocity',
    'bioAge',
    'preciseAge',
    'chronoVsBio',
    'developmentZScore',
    'growthPhase',
    'adultHeightPrediction',
    'bodyHeight',
    'bodyWeight',
    'pah90Ci'
  ]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [sidePanelData, setSidePanelData] = useState(null);
  const [error, setError] = useState(null);
  const [method, setMethod] = useState(
    GrowthTrackerEstimationMethods.KHAMIS_ROCHE
  );
  const [showColumnVisibilityModal, setShowColumnVisibilityModal] =
    useState(false);

  const [fetchGrowthDataByGroup, { loading, error: fetchError, refetch }] =
    useLazyQuery(QUERY_GET_GROWTH_DATA_OF_GROUP);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await fetchGrowthDataByGroup({
        variables: {
          entityId: groupId
        },
        fetchPolicy: 'network-only'
      });

      if (data?.getGrowthDataOfGroup2) {
        const growthData = data.getGrowthDataOfGroup2;

        usersArray.current = growthData.map(d => {
          return {
            id: d.person.id,
            person: d.person,
            alertOldData: getAlertOldData(d),
            ...getRowData(d)
          };
        });

        setRows(usersArray.current);
        setCols(GrowthTrackerColumns);
      }
    };

    if (groupId) {
      fetchData().catch(error => {
        Sentry.captureException(error);
      });
    }
  }, [groupId, location]);

  const onChangeMethod = method => {
    setMethod(method);
  };

  const onClickCell = cell => {
    const person = cell.row.original.person;
    const dataObj = {
      title: `${person.firstname} ${person.lastname}`,
      subTitle: cell.column.id,
      laterality: '2',
      chartData: {
        min: 0,
        max: 10,
        avg: null,
        benchmarkType: 'default',
        data: [
          {
            id: '525fee5b-58f3-4442-9472-f1daeac0cdae',
            data: [
              {
                x: '2024-09-08',
                y: cell.getValue().value,
                result: cell.getValue().value,
                date: '08/09/2024',
                sortDate: '2024-09-08',
                unit: 's',
                inputType: 'chrono'
              }
            ]
          }
        ]
      }
    };

    setSidePanelData(dataObj);
  };

  const onCloseSidePanel = () => setSidePanelData(null);

  return (
    <GrowthPanelContext.Provider
      value={{
        loading,
        cols,
        rows,
        columnVisibility,
        setColumnVisibility,
        sorting,
        setSorting,
        columnOrder,
        onClickCell,
        sidePanelData,
        onCloseSidePanel,
        error,
        method,
        onChangeMethod,
        showColumnVisibilityModal,
        setShowColumnVisibilityModal
      }}
    >
      {children}
    </GrowthPanelContext.Provider>
  );
};

function useGrowthPanelContext() {
  const context = useContext(GrowthPanelContext);
  if (context === undefined) {
    throw new Error(
      'The GrowthPanelContext hook must be used within a GrowthPanelContext.Provider'
    );
  }
  return context;
}

export { GrowthPanelContextProvider, useGrowthPanelContext };
