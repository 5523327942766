import React, { useEffect } from 'react';
import { Card } from '../../../components/card';
import { useParams } from 'react-router-dom';
import usePersonInputData from '../../../hooks/queries/usePersonInputData';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow
} from 'components/table';
import { sortByKey } from 'utils/object';
import Loader from 'components/loader/Loader';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';

const PersonTrackRecords = () => {
  const { entityId, sporterId } = useParams();
  const { fetchInputData, deleteDataPoint, personInputData, loading, refetch } =
    usePersonInputData();

  useEffect(() => {
    const getPersonInputData = async () => {
      await fetchInputData({ personId: sporterId });
    };
    if (sporterId) {
      getPersonInputData();
    }
  }, [entityId, sporterId]);

  const removeDataPoint = id => async () => {
    await deleteDataPoint(id);
    await refetch();
  };

  if (!personInputData) {
    return <Loader />;
  }

  return (
    <>
      <Card secondary>
        <CardHeader secondary>
          <CardHeaderTitle>Growth Tracker input data</CardHeaderTitle>
        </CardHeader>
        {loading && <Loader />}
        {Object.keys(personInputData)
          .sort((a, b) => b - a)
          .map((date, i) => (
            <div key={i}>
              <CardHeader secondary>
                <CardHeaderTitle>
                  {new Date(Number(date)).toLocaleDateString()}
                </CardHeaderTitle>
              </CardHeader>
              <Table growthPredictionTable>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell>Test Name</TableHeaderCell>
                    <TableHeaderCell>Value</TableHeaderCell>
                    <TableHeaderCell></TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {Object.keys(
                    sortByKey(personInputData[date], 'testName')
                  ).map((testItemId, j) => {
                    return (
                      <TableRow key={j}>
                        <TableCell evenHalf>
                          {personInputData[date][testItemId].testName}
                        </TableCell>
                        <TableCell evenHalf>
                          {personInputData[date][testItemId].values.join(', ')}
                        </TableCell>
                        <TableCell>
                          <Button
                            small
                            onClick={removeDataPoint(
                              personInputData[date][testItemId].id
                            )}
                          >
                            <Icon id="delete" />
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          ))}
      </Card>
    </>
  );
};

export default PersonTrackRecords;
